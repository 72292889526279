import { ENVIRONMENT_QUAL } from './qual';

export const environment = {
  ...ENVIRONMENT_QUAL,
  apiServer: 'https://detroit.app.1life.com',
  auth0: {
    audience: 'https://onemedical.com',
    clientId: 'yLk24DsScmrqeQO7RDp37mLfSI6MPU3X',
    domain: 'identity-onemedical.us.auth0.com',
  },
  myoneServer: 'https://detroit.app.1life.com',
  sentry: {
    enabled: true,
    dsn: 'https://94fb2cb964527591835f763ad4493a4f@o4506221115408384.ingest.us.sentry.io/4506855554154496',
    environment: 'onelife-detroit',
  },
  launchDarklyClientId: '605035ff9a881a0c380e4b54',
  stripePublishableKey:
    'pk_test_51NhZiMCx061y2PT8OqtVxA3QBOJUkUsk4FUb8HHjwAnun7FxXsVz3bwdDLkUCADUuCTsrSsgEpQBL3WkVoRVxVBQ00lWFro5Kv',
};
